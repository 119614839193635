import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, Component, Inject, Input, ViewChild, Output, forwardRef, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { CommonModule } from '@angular/common';
const _c0 = ["editorContainer"];
const _c1 = "[_nghost-%COMP%]{display:block;height:200px}.editor-container[_ngcontent-%COMP%]{width:100%;height:98%}";
const NGX_MONACO_EDITOR_CONFIG = new InjectionToken('NGX_MONACO_EDITOR_CONFIG');
let loadedMonaco = false;
let loadPromise;
class BaseEditor {
  set insideNg(insideNg) {
    this._insideNg = insideNg;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this._options, this.insideNg);
    }
  }
  get insideNg() {
    return this._insideNg;
  }
  constructor(config) {
    this.config = config;
    this.onInit = new EventEmitter();
    this._insideNg = false;
  }
  ngAfterViewInit() {
    if (loadedMonaco) {
      // Wait until monaco editor is available
      loadPromise.then(() => {
        this.initMonaco(this._options, this.insideNg);
      });
    } else {
      loadedMonaco = true;
      loadPromise = new Promise(resolve => {
        const baseUrl = this.config.baseUrl || "./assets";
        if (typeof window.monaco === 'object') {
          this.initMonaco(this._options, this.insideNg);
          resolve();
          return;
        }
        const onGotAmdLoader = require => {
          let usedRequire = require || window.require;
          let requireConfig = {
            paths: {
              vs: `${baseUrl}/monaco/min/vs`
            }
          };
          Object.assign(requireConfig, this.config.requireConfig || {});
          // Load monaco
          usedRequire.config(requireConfig);
          usedRequire([`vs/editor/editor.main`], () => {
            if (typeof this.config.onMonacoLoad === 'function') {
              this.config.onMonacoLoad();
            }
            this.initMonaco(this._options, this.insideNg);
            resolve();
          });
        };
        if (this.config.monacoRequire) {
          onGotAmdLoader(this.config.monacoRequire);
          // Load AMD loader if necessary
        } else if (!window.require) {
          const loaderScript = document.createElement('script');
          loaderScript.type = 'text/javascript';
          loaderScript.src = `${baseUrl}/monaco/min/vs/loader.js`;
          loaderScript.addEventListener('load', () => {
            onGotAmdLoader();
          });
          document.body.appendChild(loaderScript);
          // Load AMD loader without over-riding node's require
        } else if (!window.require.config) {
          var src = `${baseUrl}/monaco/min/vs/loader.js`;
          var loaderRequest = new XMLHttpRequest();
          loaderRequest.addEventListener("load", () => {
            let scriptElem = document.createElement('script');
            scriptElem.type = 'text/javascript';
            scriptElem.text = [
            // Monaco uses a custom amd loader that over-rides node's require.
            // Keep a reference to node's require so we can restore it after executing the amd loader file.
            'var nodeRequire = require;', loaderRequest.responseText.replace('"use strict";', ''),
            // Save Monaco's amd require and restore Node's require
            'var monacoAmdRequire = require;', 'require = nodeRequire;', 'require.nodeRequire = require;'].join('\n');
            document.body.appendChild(scriptElem);
            onGotAmdLoader(window.monacoAmdRequire);
          });
          loaderRequest.open("GET", src);
          loaderRequest.send();
        } else {
          onGotAmdLoader();
        }
      });
    }
  }
  ngOnDestroy() {
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
    }
  }
  static {
    this.ɵfac = function BaseEditor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BaseEditor)(i0.ɵɵdirectiveInject(NGX_MONACO_EDITOR_CONFIG));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BaseEditor,
      selectors: [["ng-component"]],
      viewQuery: function BaseEditor_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._editorContainer = _t.first);
        }
      },
      inputs: {
        insideNg: "insideNg"
      },
      outputs: {
        onInit: "onInit"
      },
      standalone: false,
      decls: 0,
      vars: 0,
      template: function BaseEditor_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseEditor, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_MONACO_EDITOR_CONFIG]
    }]
  }], {
    insideNg: [{
      type: Input,
      args: ['insideNg']
    }],
    _editorContainer: [{
      type: ViewChild,
      args: ['editorContainer', {
        static: true
      }]
    }],
    onInit: [{
      type: Output
    }]
  });
})();
class EditorComponent extends BaseEditor {
  set options(options) {
    this._options = Object.assign({}, this.config.defaultOptions, options);
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(options, this.insideNg);
    }
  }
  get options() {
    return this._options;
  }
  set model(model) {
    this.options.model = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.options, this.insideNg);
    }
  }
  constructor(zone, editorConfig) {
    super(editorConfig);
    this.zone = zone;
    this.editorConfig = editorConfig;
    this._value = '';
    this.propagateChange = _ => {};
    this.onTouched = () => {};
  }
  writeValue(value) {
    this._value = value || '';
    // Fix for value change while dispose in process.
    setTimeout(() => {
      if (this._editor && !this.options.model) {
        this._editor.setValue(this._value);
      }
    });
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  initMonaco(options, insideNg) {
    const hasModel = !!options.model;
    if (hasModel) {
      const model = monaco.editor.getModel(options.model.uri || '');
      if (model) {
        options.model = model;
        options.model.setValue(this._value);
      } else {
        options.model = monaco.editor.createModel(options.model.value, options.model.language, options.model.uri);
      }
    }
    if (insideNg) {
      this._editor = monaco.editor.create(this._editorContainer.nativeElement, options);
    } else {
      this.zone.runOutsideAngular(() => {
        this._editor = monaco.editor.create(this._editorContainer.nativeElement, options);
      });
    }
    if (!hasModel) {
      this._editor.setValue(this._value);
    }
    this._editor.onDidChangeModelContent(e => {
      const value = this._editor.getValue();
      // value is not propagated to parent when executing outside zone.
      this.zone.run(() => {
        this.propagateChange(value);
        this._value = value;
      });
    });
    this._editor.onDidBlurEditorWidget(() => {
      this.onTouched();
    });
    // refresh layout on resize event.
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    this._windowResizeSubscription = fromEvent(window, 'resize').subscribe(() => this._editor.layout());
    this.onInit.emit(this._editor);
  }
  static {
    this.ɵfac = function EditorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(NGX_MONACO_EDITOR_CONFIG));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EditorComponent,
      selectors: [["ngx-monaco-editor"]],
      inputs: {
        options: "options",
        model: "model"
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EditorComponent),
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 0,
      consts: [["editorContainer", ""], [1, "editor-container"]],
      template: function EditorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 1, 0);
        }
      },
      styles: ["[_nghost-%COMP%]{display:block;height:200px}.editor-container[_ngcontent-%COMP%]{width:100%;height:98%}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-monaco-editor',
      template: '<div class="editor-container" #editorContainer></div>',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EditorComponent),
        multi: true
      }],
      styles: [":host{display:block;height:200px}.editor-container{width:100%;height:98%}\n"]
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_MONACO_EDITOR_CONFIG]
    }]
  }], {
    options: [{
      type: Input,
      args: ['options']
    }],
    model: [{
      type: Input,
      args: ['model']
    }]
  });
})();
class DiffEditorComponent extends BaseEditor {
  set options(options) {
    this._options = Object.assign({}, this.config.defaultOptions, options);
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(options, this.insideNg);
    }
  }
  get options() {
    return this._options;
  }
  set originalModel(model) {
    this._originalModel = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.options, this.insideNg);
    }
  }
  set modifiedModel(model) {
    this._modifiedModel = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.options, this.insideNg);
    }
  }
  constructor(zone, editorConfig) {
    super(editorConfig);
    this.zone = zone;
    this.editorConfig = editorConfig;
  }
  initMonaco(options, insideNg) {
    if (!this._originalModel || !this._modifiedModel) {
      throw new Error('originalModel or modifiedModel not found for ngx-monaco-diff-editor');
    }
    this._originalModel.language = this._originalModel.language || options.language;
    this._modifiedModel.language = this._modifiedModel.language || options.language;
    let originalModel = monaco.editor.createModel(this._originalModel.code, this._originalModel.language);
    let modifiedModel = monaco.editor.createModel(this._modifiedModel.code, this._modifiedModel.language);
    this._editorContainer.nativeElement.innerHTML = '';
    const theme = options.theme;
    if (insideNg) {
      this._editor = monaco.editor.createDiffEditor(this._editorContainer.nativeElement, options);
    } else {
      this.zone.runOutsideAngular(() => {
        this._editor = monaco.editor.createDiffEditor(this._editorContainer.nativeElement, options);
      });
    }
    options.theme = theme;
    this._editor.setModel({
      original: originalModel,
      modified: modifiedModel
    });
    // refresh layout on resize event.
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    this._windowResizeSubscription = fromEvent(window, 'resize').subscribe(() => this._editor.layout());
    this.onInit.emit(this._editor);
  }
  static {
    this.ɵfac = function DiffEditorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DiffEditorComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(NGX_MONACO_EDITOR_CONFIG));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DiffEditorComponent,
      selectors: [["ngx-monaco-diff-editor"]],
      inputs: {
        options: "options",
        originalModel: "originalModel",
        modifiedModel: "modifiedModel"
      },
      standalone: false,
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 0,
      consts: [["editorContainer", ""], [1, "editor-container"]],
      template: function DiffEditorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 1, 0);
        }
      },
      styles: [_c1]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DiffEditorComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-monaco-diff-editor',
      template: '<div class="editor-container" #editorContainer></div>',
      styles: [":host{display:block;height:200px}.editor-container{width:100%;height:98%}\n"]
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_MONACO_EDITOR_CONFIG]
    }]
  }], {
    options: [{
      type: Input,
      args: ['options']
    }],
    originalModel: [{
      type: Input,
      args: ['originalModel']
    }],
    modifiedModel: [{
      type: Input,
      args: ['modifiedModel']
    }]
  });
})();
class MonacoEditorModule {
  static forRoot(config = {}) {
    return {
      ngModule: MonacoEditorModule,
      providers: [{
        provide: NGX_MONACO_EDITOR_CONFIG,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function MonacoEditorModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MonacoEditorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MonacoEditorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MonacoEditorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [EditorComponent, DiffEditorComponent],
      exports: [EditorComponent, DiffEditorComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DiffEditorComponent, EditorComponent, MonacoEditorModule, NGX_MONACO_EDITOR_CONFIG };
